import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

import { Typography } from '@mui/material';
import Container from 'components/Container';
import Sidebar2024 from '../MathClub2023/components/Sidebar2024';
import Archive2022 from 'views/MathClub/components/Archive2022';


const MathCCA2024 = () => {
    const theme = useTheme();
    const [currentImage, setCurrentImage] = useState(0);
    const [viewerIsOpen, setViewerIsOpen] = useState(false);

    const openLightbox = (index) => {
        setCurrentImage(index);
        setViewerIsOpen(true);
    };

    const closeLightbox = () => {
        setCurrentImage(0);
        setViewerIsOpen(false);
    };

    const isMd = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    });

    const [config, setConfig] = useState({ base_image_archive_url: '' });
    useEffect(() => {
        fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
            .then(response => response.json())
            .then(data => setConfig(data))
            .catch(error => console.error('Error fetching config:', error));
    }, []);

    const p1 = `${config.base_image_url}/home/events-activities/association/MathClub/CCA3to5/1.webp`;
    const p2 = `${config.base_image_url}/home/events-activities/association/MathClub/CCA3to5/2.webp`;
    const p3 = `${config.base_image_url}/home/events-activities/association/MathClub/CCA3to5/3.webp`;
    const p4 = `${config.base_image_url}/home/events-activities/association/MathClub/CCA3to5/4.webp`;


    const photos = [
        {
            src: p1,
            source: p1,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p3,
            source: p3,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p2,
            source: p2,
            rows: 1.5,
            cols: 2,
        },
        {
            src: p4,
            source: p4,
            rows: 1.5,
            cols: 2,
        },

    ];

    return (
        <Main>
            <Container>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={8}>
                        <Box>

                            <Box >
                                <Typography variant='h4' align='center'>
                                    MATHEMATICS CCA
                                </Typography>
                                <br></br>
                                <Typography variant='h7' align='justify' >
                                    Class 3: Character creation using tangrams (Story telling)
                                    <br></br>
                                    Class 4: Character creation using tangrams (Role Play: Healthy and unhealthy)
                                    <br></br>
                                    Class 5: Character creation using tangrams (Puppet Show)
                                </Typography>
                                <Typography
                                    fontSize="x-small"
                                ><br /><br />
                                    Classes: 3 to 5   Date: 25 October, 24 October and 16 October 2024
                                </Typography>

                                <br />
                                <Typography variant={'subtitle1'} align={'justify'}>
                                    The Mathematics CCA, held during the third and fourth weeks of October, showcased active and enthusiastic participation from students in Classes 3-5.
                                    <br></br>
                                    Class 3 students confidently presented a story by creating characters using tangrams to bring their tales to life, which also highlighted their public speaking skills.
                                    <br></br>
                                    Class 4 students performed a role play on the theme "healthy and unhealthy food habits," incorporating tangram characters to convey their message.
                                    <br></br>
                                    Class 5 students staged an engaging puppet show, featuring characters crafted with tangrams.
                                    This activity fostered collaborative learning, deepened students understanding of geometric shapes and sparked creativity.
                                    <br></br>
                                    Each group’s unique approach made this event a vibrant celebration of talent and creativity.
                                </Typography>
                            </Box>
                            
                            <Box>
                                <ImageList
                                    variant="quilted"
                                    cols={2}
                                    rowHeight={isMd ? 200 : 100}
                                    gap={isMd ? 16 : 4}
                                >
                                    {photos.map((item, i) => (
                                        <ImageListItem key={i} cols={item.cols} rows={item.rows}>
                                            <LazyLoadImage
                                                height={'100%'}
                                                width={'100%'}
                                                src={item.src}
                                                alt="..."
                                                effect="blur"
                                                onClick={() => openLightbox(i)}
                                                style={{
                                                    objectFit: 'cover',
                                                    filter:
                                                        theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                                                    cursor: 'poiner',
                                                    borderRadius: 8,
                                                }}
                                            />
                                        </ImageListItem>
                                    ))}
                                </ImageList>
                            </Box>
                            {viewerIsOpen && (
                                <Lightbox
                                    mainSrc={photos[currentImage].src}
                                    nextSrc={photos[(currentImage + 1) % photos.length].src}
                                    prevSrc={
                                        photos[(currentImage + photos.length - 1) % photos.length].src
                                    }
                                    onCloseRequest={() => closeLightbox()}
                                    onMovePrevRequest={() =>
                                        setCurrentImage((currentImage + photos.length - 1) % photos.length)
                                    }
                                    onMoveNextRequest={() =>
                                        setCurrentImage((currentImage + 1) % photos.length)
                                    }
                                    reactModalStyle={{ overlay: { zIndex: 1500 } }}
                                />
                            )}
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box marginBottom={4}>
                            <Sidebar2024 />
                        </Box>
                        <Box marginBottom={4}>
                            <Archive2022/>
                        </Box>

                    </Grid>
                </Grid>
            </Container>
        </Main>

    );
};

export default MathCCA2024;